import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ReactGA from 'react-ga4';
import OtpInput from 'react-otp-input';
import packageJson from '../../../package.json';
import amountToImageMap from '../mobile/PackageAmountMap';
import { sign } from 'jsonwebtoken';
import { setAccessData } from '../../stores/authorization/AuthorizationState';
import { resetLessonSet } from '../../stores/lesson-list/LessonSetListState';
import { reloadTransaction } from '../../stores/transaction/Transaction';
import { MixpanelTracking } from '../../utility';
import { toast, ToastContainer } from 'react-toastify';

import './PayMobileGuest.css';
import loading from '../../images/loading/loading_spinner.svg';
import msgIcon from '../../images/facebook/msg-icon.svg';
import phone from '../../images/other/phone.svg';
import promptPay from '../../images/bank/PromptPay2.png';
import userBlue from '../../images/user/user-blue.svg';



const PayMobileGuest = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);

    const [inputName, setInputName] = useState('');
    const [inputPhone, setInputPhone] = useState('');
    const [isOtpButtonLocked, setIsOtpButtonLocked] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpCountdown, setOtpCountdown] = useState(0);
    const [otpRequested, setOtpRequested] = useState(false);
    const [sendSubmit, setSendSubmit] = useState(false);
    const [showMessenger, setShowMessenger] = useState(false);

    const [fetchedAccessToken, setFetchedAccessToken] = useState<string>('');
    const [fetchedAccountId, setFetchedAccountId] = useState<string>('');
    const [fetchedAccount, setFetchedAccount] = useState<any>(null);

    const [amount, setAmount] = useState<number>(0);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedProductId, setSelectedProductId] = useState<string>('');

    const appVersion = `${packageJson.name} ${packageJson.version}`;
    const jwtSecret = process.env.REACT_APP_JWT_SECRET;



    if (!jwtSecret) {
        throw new Error("JWT_SECRET is not defined in environment variables");
    }



    const generateToken = (phoneNumber: string, subject: string) => {
        return sign({}, Buffer.from(jwtSecret), {
            issuer: 'webnaschool', algorithm: 'HS256', noTimestamp: true, jwtid: phoneNumber, subject: subject
        });
    };

    const requestOtp = async () => {
        if (isOtpButtonLocked || !inputPhone) return;

        setIsOtpButtonLocked(true);
        startOtpCountdown();
        const token = generateToken(inputPhone, inputPhone);

        try {
            const checkRes = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/payment/check/phone`, {
                method: 'POST',
                body: JSON.stringify({ phone: inputPhone }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': token,
                },
            });

            if (checkRes.ok) {
                const checkData = await checkRes.json();
                setIsPremium(checkData.error === 0 && checkData.data === true);
            } else {
                throw new Error('Failed to check phone premium status');
            }

            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v3/method/smsSignUp`, {
                method: 'POST',
                body: `access_token=${token}&phoneNumber=${inputPhone}&action=requestOTP`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            if (res.ok) {
                toast.success('ส่งรหัส OTP ไปแล้ว!', { position: toast.POSITION.TOP_CENTER });
                setOtpRequested(true);
            } else {
                toast.error('เกิดข้อผิดพลาดขณะทำการส่งรหัส กรุณาลองใหม่อีกครั้ง', { position: toast.POSITION.TOP_CENTER });
            }
            return res.json();

        } catch (error) {
            toast.error('เกิดข้อผิดพลาดขณะทำการส่งรหัส กรุณาลองใหม่อีกครั้ง', { position: toast.POSITION.TOP_CENTER });
            return error;
        }
    };

    const verifyOtp = async () => {
        const token = generateToken(inputPhone, otp);
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v3/method/smsSignUp`, {
            method: 'POST',
            body: `access_token=${token}&phoneNumber=${inputPhone}&action=verifyOTP`,
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        });
        return res.json().catch((e) => e);
    };

    const trySignInWithPhoneNumberAndOTP = async () => {
        const token = generateToken(inputPhone, otp);
        const formData = new URLSearchParams({
            access_token: token, phoneNumber: inputPhone, appversion: appVersion, clientId: '2016'
        });

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/account.signIn`, {
            method: 'POST',
            body: formData,
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        });
        return res.json().catch((e) => e);
    };

    const signUpWithPhoneNumber = async (param: any) => {
        try {
            const token = generateToken(inputPhone, otp);
            const body = new URLSearchParams({
                access_token: token, phoneNumber: inputPhone, appversion: appVersion, clientId: '2016', fullname: param.fullname, username: param.username,
                province: "10", country: "TH", language: "TH", grade: "E5", parent_email: "", email: "", sex: "1"
            });

            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/account.signUp`, {
                method: 'POST',
                body: body.toString(),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            return await res.json();
        } catch (e) {
            return e;
        }
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (otp.length !== 4) {
            toast('กรุณากรอกรหัส OTP');
            return
        };
        if (!selectedFile) {
            toast('กรุณาอัปโหลดภาพสลิปโอนเงิน');
            return;
        };
        setSendSubmit(true);

        try {
            const verifyRes = await verifyOtp();
            if (verifyRes.error) {
                toast.error(verifyRes.error_description, { position: "top-center", autoClose: 5000, hideProgressBar: false });
                return;
            }

            const { upload_url, file_id } = await getSlackFileUploadUrl(selectedFile.name, selectedFile.size);
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('uploadUrl', upload_url);
            await uploadFileToServer(formData);
            await completeSlackUpload(file_id, inputName);
            await handleSignInOrSignUp();
            setShowMessenger(true);

        } catch (error) {
            console.error("Error during verification/sign-in:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");

        } finally {
            setSendSubmit(false);
        }
    };

    const handleSignInOrSignUp = async () => {
        try {
            const signInRes = await trySignInWithPhoneNumberAndOTP();

            if (signInRes.error_code === 404) {
                await handleSignUp();

            } else if (signInRes.error_code === 0) {
                MixpanelTracking.identify(signInRes.accountId);
                MixpanelTracking.signIn();
                dispatch(resetLessonSet());
                dispatch(setAccessData({ requestLogin: false, accessToken: signInRes.accessToken, accountId: signInRes.accountId, account: signInRes.account[0] }));

                if (!isPremium) {
                    await createPrePromptPay(signInRes.accountId, generateToken(inputPhone, inputPhone));
                } else {
                    history.push('/payment-history');
                }

            } else if (signInRes.error) {
                toast.error(signInRes.error_description, { position: "top-center", autoClose: 5000, hideProgressBar: false });

            } else {
                toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
            }
        } catch (error) {
            console.error("Error during sign-in/sign-up:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
        }
    };

    const handleSignUp = async () => {
        try {
            const [firstName] = inputName.split(' ');
            const { accountId, account, accessToken, error_code, error_description } = await signUpWithPhoneNumber({
                fullname: inputName, username: firstName
            });

            setFetchedAccessToken(accessToken);
            setFetchedAccountId(accountId);
            setFetchedAccount(account);

            if (error_code === 0) {
                MixpanelTracking.alias(accountId);
                MixpanelTracking.identify(accountId);
                MixpanelTracking.signUp('Phone', account[0]);
                ReactGA.event({ category: 'Account', action: 'Sign Up' });
                dispatch(resetLessonSet());
                await createPrePromptPay(accountId, generateToken(inputPhone, inputPhone));

            } else {
                toast(`ไม่สามารถสมัครได้เนื่องจาก : ${error_description} กรุณาติดต่อ Admin`);
            }
        } catch (error) {
            console.error("Sign-up error:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
        }
    };

    const createPrePromptPay = async (userId: string, token: string) => {
        try {
            await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/payment/create/pre/promptpay`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': token,
                },
                body: JSON.stringify({
                    userId,
                    productId: selectedProductId,
                }),
            });
        } catch (error) {
            console.error("Error creating pre-prompt pay:", error);
            throw error;
        }
    };

    const startOtpCountdown = () => {
        setOtpCountdown(15);

        const countdownInterval = setInterval(() => {
            setOtpCountdown((prevTime) => prevTime - 1);
        }, 1000);

        setTimeout(() => {
            setIsOtpButtonLocked(false);
            clearInterval(countdownInterval);
            setOtpCountdown(0);
        }, 15000);
    };

    const changePlan = async (selectPlan: string) => {
        const product = productList.find((t) => t.product_id === selectPlan);
        const at = accessData.accessToken ? accessData.accessToken : '';
        const aid = accessData.accountId ? accessData.accountId : '';

        dispatch(reloadTransaction(at, aid));
        setAmount(parseInt(product.price));
        setSelectedProductId(selectPlan);
    };

    const handleMessenger = () => {
        dispatch(setAccessData({ requestLogin: true, accessToken: fetchedAccessToken, accountId: fetchedAccountId, account: fetchedAccount[0] }));
        history.push('/profile/edit');
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const maxSizeBytes = 5 * 1024 * 1024; // 5MB

        if (!file.type.startsWith('image/')) {
            toast('กรุณาอัปโหลดภาพสลิปโอนเงิน');
        } else if (file.size > maxSizeBytes) {
            toast('ขนาดไฟล์ใหญ่เกิน 5MB กรุณาอัปโหลดรูปที่มีขนาดเล็กกว่านี้');
        } else {
            setSelectedFile(file);
            const fileNameDisplay = document.getElementById("payMobileFileName");
            if (fileNameDisplay) fileNameDisplay.textContent = file.name;
            return;
        }

        event.target.value = '';
        setSelectedFile(null);
    };

    const getSlackFileUploadUrl = async (filename: string, length: number) => {
        const userToken = generateToken(inputPhone, inputPhone);
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': userToken };
        const body = JSON.stringify({ filename, length });

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/getUploadURLExternal`,
                { method: 'POST', headers, body }
            );
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const uploadFileToServer = async (formData: FormData) => {
        const userToken = generateToken(inputPhone, inputPhone);
        const headers: HeadersInit = { 'token': userToken };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/uploadExternal`,
                { method: 'POST', headers, body: formData }
            );
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const completeSlackUpload = async (file_id: string, name: string) => {
        const userToken = generateToken(inputPhone, inputPhone);
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': userToken };
        const body = JSON.stringify({ file_id, name });

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/completeUploadExternal`,
                { method: 'POST', headers, body }
            );
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };



    useEffect(() => {
        if (accessData.accessToken) history.push('/payment-history');
    }, [accessData]);

    useEffect(() => {
        const at = accessData.accessToken ? accessData.accessToken : '';
        const aid = accessData.accountId ? accessData.accountId : '';
        dispatch(reloadTransaction(at, aid));
    }, []);

    useEffect(() => {
        if (productList.length > 0 && !selectedProductId) {
            setSelectedProductId(productList[0].product_id);
            setAmount(parseInt(productList[0].price));
        };
    }, [productList]);



    return (
        <form onSubmit={handleFormSubmit}>
            <div className="mobile-pay-bg-sign-up">
                <div className="row">

                    <div className="mobile-pay-header col-12 pt-4"><h3>สมัครใช้บริการ</h3></div>

                    <div className="col-12 mobile-pay-input-signup">
                        <img src={userBlue} alt="User Icon" />
                        <input placeholder="ชื่อ" value={inputName}
                            onChange={(e) => setInputName(e.target.value)}
                            required
                        >
                        </input>
                    </div>

                    <div className="col-12 mobile-pay-input-signup">
                        <img src={phone} alt="Phone Icon" />
                        <input placeholder="เบอร์โทรศัพท์" value={inputPhone}
                            onChange={(e) => setInputPhone(e.target.value)}
                            required
                        >
                        </input>
                    </div>

                    <div className="otp-request-button-container col-12">
                        <p className="login-text">กรุณากดปุ่ม "ขอรหัส"</p>
                        <p className="login-text">เพื่อ NockAcademy จะได้ส่งรหัส OTP ไปทาง SMS ของคุณ</p>
                        <p className="login-text">หากคุณยังไม่ได้รับรหัสนี้ กรุณาลองกด "ขอรหัส" ใหม่อีกครั้ง</p>
                        <button
                            id="otp-request-button"
                            onClick={() => requestOtp()}
                            className="btn btn-primary"
                            disabled={isOtpButtonLocked}
                        >
                            {isOtpButtonLocked ? (
                                <span>กดส่งได้อีกครั้งใน: {otpCountdown} วินาที</span>
                            ) : (
                                <span>ขอรหัส</span>
                            )}
                        </button>
                    </div>

                    {otpRequested && (
                        <div className="mobile-pay-otp-container col-12">
                            <div className='row'>
                                <div className="col-10 col-sm-8 otp-container">
                                    <OtpInput value={otp} onChange={(e: string) => setOtp(e)} numInputs={4} inputStyle={"otp-input"} separator={<span></span>} isInputNum={true} />
                                </div>
                            </div>
                        </div>
                    )}

                    {otpRequested && !isPremium && (
                        <div className="mobile-pay-step-2 col-12">
                            <div className="mobile-pay-premium-text-payment col-12">
                                <div>คอร์สเรียนที่ท่านเลือกอยู่ตอนนี้คือ</div>
                                <div className="select-wrapper">
                                    <select
                                        value={selectedProductId}
                                        onChange={(e) => changePlan(e.target.value)}
                                        className="form-select-sm mobile-pay-form-payment"
                                    >
                                        {productList.map((m) => (
                                            <option key={m.product_id} value={m.product_id}>{m.description_th}</option>
                                        ))}
                                    </select>
                                    <span className="select-arrow">▼</span>
                                </div>
                            </div>

                            <div className="mobile-pay-price-display col-12">
                                ยอดที่ต้องชำระ – {amount.toFixed(2)} บาท
                            </div>

                            <div className="mobile-pay-qr col-12">
                                <img id="promptpay-logo" src={promptPay} alt="PromptPay Logo" />
                                <img src={amountToImageMap[amount] || ''} alt={`PromptPay QR code for ${amount} baht`} />
                                <p>หมายเลขอ้างอิง: 0-2055-57003-96-5</p>
                            </div>

                            <div className="mobile-pay-notes col-12">
                                <p>ชำระผ่าน Mobile Banking ได้โดย</p>
                                <ul className="mobile-pay-notes-list">
                                    <li>Capture หน้าจอโทรศัพท์</li>
                                    <li>นำภาพ QR ชำระผ่าน App ของธนาคาร</li>
                                    <li>อัปโหลดสลิปหลักฐานการโอนเงินผ่านปุ่มด้านล่าง</li>
                                    <li>ยอมรับเงื่อนไขและข้อตกลง แล้วกดสมัครใช้บริการ</li>
                                    <li>แจ้งการชำระเงินกับแอดมินผ่านทาง Messenger</li>
                                </ul>
                            </div>

                            <div className="pay-mobile-image-upload col-12">
                                <input type="file" id="fileUpload" onChange={handleFileChange} />
                                <label htmlFor="fileUpload">อัปโหลดสลิป</label>
                                <span id="payMobileFileName"></span>
                            </div>

                            <div className="mobile-pay-requirements col-12 mt-4 d-flex align-items-center">
                                <p>ข้าพเจ้ายอมรับ</p>
                                <p>
                                    <label>
                                        <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/terms-and-conditions">เงื่อนไข </a>
                                        และ
                                        <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/privacy-and-cookie-policy"> ข้อตกลงการใช้บริการ</a>
                                    </label>
                                    <span style={{ marginLeft: "10px" }}></span>
                                    <input className="mobile-pay-accept mr-2" type="checkbox" id="accept-terms" required />
                                </p>
                            </div>
                        </div>
                    )}

                    {otpRequested && !showMessenger && (
                        <div className="col-12 mobile-pay-register-button-container">
                            <button id="mobile-pay-register-button" className="m-auto" type="submit" disabled={sendSubmit}>
                                {isPremium ? 'เข้าสู่ระบบ' : 'สมัครใช้บริการ'}
                                <img style={{ maxHeight: "8vh" }} src={loading} hidden={!sendSubmit} />
                            </button>
                        </div>
                    )}

                    {otpRequested && showMessenger && !isPremium && (
                        <div className="pay-mobile-messenger-container col-12">
                            <a
                                href={`https://m.me/NockAcademyElementary?text=ขอแจ้งโอนเงินสำหรับผู้ใช้งาน%20–%20${inputName}`}
                                className="pay-mobile-message-us-button col-12"
                                target="_blank"
                                onClick={handleMessenger}
                            >
                                <img src={msgIcon} alt="Message Icon" className="msg-icon" />
                                แจ้งการชำระเงิน
                            </a>
                        </div>
                    )}

                </div>
            </div>

            <ToastContainer
                position="top-center" autoClose={3000} hideProgressBar newestOnTop
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover
            />
        </form >
    );
};

export default PayMobileGuest;
