import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './PaymentHistoryList.css';
import moment from 'moment';
import 'moment/locale/th';
import {generateToken} from '../../utility/Token';
import openReceiptIcon from '../../images/actions/open-receipt-icon.svg';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {reloadTransaction} from '../../stores/transaction/Transaction';
import {Link} from 'react-router-dom';
import {setTransactionStatus} from '../../stores/shopee-pay/ShopeePayState';

const PaymentHistoryList = (props: {
  title: string,
  transactionList: Array<any>,
}) => {
  const dispatch = useDispatch();
  const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
  const [isCheckStatusShopeePay, setIsCheckStatusShopeePay] = useState(false);
  const openPdf = (transactionId: any) => {
    const token = generateToken(accessData.accountId!, accessData.accessToken!);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${process.env.REACT_APP_API_URL_V2}/v1/receipt/getReceipt/${transactionId}`, true);
    xhr.setRequestHeader('token', token);
    xhr.responseType = 'blob';
    xhr.onreadystatechange = function() {
      console.log(xhr.readyState );
      if (xhr.readyState === 4) {
        const url = URL.createObjectURL(xhr.response);
        window.open(url, '_blank');
      }
    };
    xhr.send(null);
  };

  const cancelSubscribe = (extTransactionId: string) => {
    const at = accessData.accessToken?accessData.accessToken:'';
    const aid = accessData.accountId?accessData.accountId:'';
    const userToken = generateToken(aid, at);

    fetch(`${process.env.REACT_APP_API_URL_V2}/v1/omise/subscribe/cancel/${extTransactionId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': userToken,
      },
    })
        .then((res) => res.json())
        .then((res) => {
          if (res.error === 0) {
            dispatch(reloadTransaction(at, aid));
          }
        });
  };

  const cancelSubscribeConfirm = (extTransactionId: string, expireTime: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return getConfirmLayout(onClose, extTransactionId, expireTime);
      },
    });
  };

  const checkTransactionStatusShopeePay = async (transactionId: string) => {
    const at = accessData.accessToken?accessData.accessToken:'';
    const aid = accessData.accountId?accessData.accountId:'';
    const userToken = generateToken(aid, at);
    setIsCheckStatusShopeePay(true);
    const apiRes = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/shopee/check/${transactionId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': userToken,
      },
    }).then((res) => res.json());
    setIsCheckStatusShopeePay(false);
    if (apiRes.errcode === 0) {
      dispatch(setTransactionStatus(apiRes.api_result.transaction.status));
      dispatch(reloadTransaction(at, aid));
    }
  };

  const getConfirmLayout = (onClose: Function, extTransactionId: string, expireTime: string) => {
    return (
      <div className='cancel-confirm-custom-ui'>
        <h1>ต้องการยกเลิก Subscribe?</h1>
        <p>คุณจะยังคงสามารถใช้บริการพรีเมี่ยมจนกว่าจะหมดอายุการใช้งาน</p>
        <p>วันที่ {moment(expireTime).format('LL')}</p>
        <Button variant="secondary"
          onClick={() => {
            onClose();
          }}>ไม่</Button>
        <Button variant="danger"
          onClick={() => {
            cancelSubscribe(extTransactionId);
            onClose();
          }}>ใช่</Button>
      </div>
    );
  };

  return (
    <div className="payment-history-list-container">
      <p className="payment-history-list-titie">{props.title}</p>
      { props.transactionList.length !== 0 && accessData.accountId ? (
        <>
          { props.transactionList.map((transaction) => (
            <div key={transaction.transaction_id} className="row payment-history-list-item-container">
              <div className="col-12 col-md-3">
                <div className="payment-history-list-item-profile-container">
                  <img className="payment-history-list-item-profile-image" src={accessData.account.lowPhotoUrl} alt=""/>
                  <div className="payment-history-list-item-profile-detail-container">
                    <p className="payment-history-list-item-profile-fullname-text">{accessData.account.fullname}</p>
                    <p className="payment-history-list-item-profile-login-text">{accessData.account.login}</p>
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-9">
                <div className="row h-100">
                  <div className="col-12 col-md-3 p-0">
                    <div className="payment-history-list-item-transaction-container">
                      <p className="payment-history-list-item-transaction-date-text">{moment(transaction.create_time).format('LL')}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 p-0">
                    <div className="payment-history-list-item-transaction-container">
                      <p className="payment-history-list-item-transaction-date-text">{moment(transaction.expire_time).format('LL')}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 p-0">
                    <div className="payment-history-list-item-transaction-container">
                      { transaction.status === '1' &&
                        <p className="payment-history-list-item-transaction-success-text">กำลังใช้งานอยู่ (ชำระเงินแล้ว)</p>
                      }
                      { transaction.status === '2' &&
                        <p className="payment-history-list-item-transaction-gray-text">ไม่ได้ใช้แล้ว หรือหมดอายุแล้ว</p>
                      }
                      { transaction.status === '3' &&
                        <Link to="/pay">
                          <p className="payment-history-list-item-transaction-pending-text">ชำระเงิน</p>
                        </Link>
                      }
                      { transaction.status === '4' &&
                        <p className="payment-history-list-item-transaction-confirm-text">รอตรวจสอบการชำระเงิน</p>
                      }
                    </div>
                  </div>
                  <div className="col-12 col-md-2 p-0">
                    <div className="payment-history-list-item-transaction-container">
                      {
                        ['1', '2', '7'].some((c) => c === transaction.payment_method && transaction.confirm_payment_time != null) ?
                        <img className="open-receipt-button" src={openReceiptIcon} alt="nockacademy plan get receipt" onClick={() => openPdf(transaction.transaction_id)}/> 
                        : <></>
                      }
                    </div>
                  </div>
                  <div className="col-12 col-md-2 p-0">
                    <div className="payment-history-list-item-transaction-container cancle-button-payment-history">
                      { (transaction.payment_method === '7' && transaction.auto_renew === '1') &&
                        <Button variant="outline-danger" onClick={() => {
                          cancelSubscribeConfirm(transaction.ext_transaction_id, transaction.expire_time);
                        }}>ยกเลิก</Button>
                       } 
                      { (transaction.payment_method === '10' && transaction.status === '4') &&
                        <>
                          {
                            isCheckStatusShopeePay ? <Spinner animation="border" variant="primary" /> :
                            <Button variant="link" onClick={() => {
                              checkTransactionStatusShopeePay(transaction.transaction_id);
                            }}>เช็คสถานะ</Button>
                          }
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
          }
        </>
      ) : (
        <>
          <div className="row payment-history-list-item-container">
            <p className="payment-history-list-empty-item m-0">ไม่มีประวัติการทำรายการ</p>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentHistoryList;
