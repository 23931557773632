import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLiveStreamHistoryRecent } from '../../stores/live-stream-history/LiveStreamHistoryRecentState';
import { getQuarterExam } from '../../stores/exam/ExamListState';
import { setGradeFilter, setSubjectFilter, setTypeFilter } from '../../stores/filter/FilterState';
import { toast } from 'react-toastify';
import './SidebarFilter.css';



const SidebarFilter = (props: { isPlaybackTopic?: boolean }) => {
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const filterState = useSelector((state: NaTypes.Store) => state.filter);
    const gradeState = useSelector((state: NaTypes.Store) => state.grade);
    const playbackTypeState = useSelector((state: NaTypes.Store) => state.playbackType);
    const subjectState = useSelector((state: NaTypes.Store) => state.subject);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const dispatch = useDispatch();
    const showPayment = !!(accessData.accessToken && !transaction.isPremium);



    const handleSelectSubject = (id: string) => dispatch(setSubjectFilter(id));
    const handleSelectType = (id: string) => dispatch(setTypeFilter(id));

    const handleSelectGrade = (id: string, name: string) => {
        toast(`ขณะนี้กำลังเลือกระดับชั้น ${name}`);
        dispatch(setGradeFilter(id));
        if (accessData) {
            dispatch(getQuarterExam(accessData, id))
            dispatch(fetchLiveStreamHistoryRecent(accessData, `?grade=${id}`))
        };
        handleSelectSubject("");
        handleSelectType("");
    };



    return (
        <div className="sidebar-card" id="filter-menu">
            <div className="col-12 filter-section pb-3">
                <div className="row">
                    <div className="col-12">
                        <p className="filter-item-title rainbow_text_animated">เกรด:</p>
                    </div>
                    <div className="col-sm-10 col-md-11">
                        {gradeState.grades.map((grade) => {
                            const isActive = grade.grade === filterState.gradeId;
                            return (
                                <div
                                    key={grade.grade}
                                    className={`filter-item-select-card${isActive ? '-active' : ''}`}
                                    onClick={() => handleSelectGrade(grade.grade, grade.descriptionTh)}
                                >
                                    <div className={`filter-item-select-icon${isActive ? '-active' : ''}`}></div>
                                    <span className="filter-item-select-title">{grade.descriptionTh}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="col-12 filter-section pb-3">
                <div className="row">
                    <div className="col-12">
                        <p className="filter-item-title rainbow_text_animated">วิชา:</p>
                    </div>
                    {subjectState.subjects.map((subject) => {
                        const isHidden = !(subject.enableSubjectGrade == null || subject.enableSubjectGrade?.includes(filterState.gradeId));
                        const isActive = subject.subjectId === filterState.subjectId;
                        return (
                            <div className="col-12" key={subject.subjectId} hidden={isHidden}>
                                <div
                                    className={`filter-item-select-card${isActive ? '-active' : ''}`}
                                    onClick={() => handleSelectSubject(subject.subjectId)}
                                >
                                    <div className={`filter-item-select-icon${isActive ? '-active' : ''}`}></div>
                                    <span className="filter-item-select-title">{subject.descriptionTh}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {props.isPlaybackTopic && (
                <div className="col-12 filter-section pb-3">
                    <div className="row">
                        <div className="col-12">
                            <p className="filter-item-title rainbow_text_animated">ประเภท:</p>
                        </div>
                        {playbackTypeState.playbackTypes.map((type) => {
                            const isActive = type.typeId === filterState.typeId;
                            return (
                                <div className="col-12" key={type.typeId}>
                                    <div
                                        className={`filter-item-select-card${isActive ? '-active' : ''}`}
                                        onClick={() => handleSelectType(type.typeId)}
                                    >
                                        <div className={`filter-item-select-icon${isActive ? '-active' : ''}`}></div>
                                        <span className="filter-item-select-title">{type.descriptionTh}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {showPayment && (
                <div className="col-12 filter-section pb-3">
                    <div className="row">
                        <div className="col-12">
                            <a href={`${process.env.REACT_APP_PAY_PORTAL}`}>
                                <div className='premium-regis'>
                                    สมัครใช้บริการ
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SidebarFilter;
