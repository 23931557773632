import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateProfile } from '../../api/Profile';
import './EditProfile.css';
import userBlue from '../../images/user/user-blue.svg';
import userOrange from '../../images/user/user-orange.svg';
import userGreen from '../../images/user/user-green.svg';
import provinces from '../../json/province.json';
import dropDown from '../../images/other/dropdown.svg';
import location from '../../images/other/location.svg';
import grade from '../../images/other/grade.svg';
import phone from '../../images/other/phone.svg';
import UploadProfile from '../../components/profile/UploadProfile';
import email from '../../images/other/email.svg';


const EditProfile = () => {
  const accessData: NaTypes.Authenticate = useSelector((state: NaTypes.Store) => state.authenticate);
  const profileState: NaTypes.ProfileState = useSelector((state: NaTypes.Store) => state.profile);
  const gradeState = useSelector((state: NaTypes.Store) => state.grade);
  const [fullname, setFullname] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [bio, setBio] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [gradeCode, setGradeCode] = useState<string>('');
  const [provinceCode, setProvinceCode] = useState<number>(0);
  const [school, setSchool] = useState<string>('');
  const [emailSubscribe, setEmailSubscribe] = useState<boolean>(false);
  const uploader = useRef<UploadProfileRefs>();
  const [userEmail, setEmail] = useState<string>('');
  const [parentEmail, setParentEmail] = useState<string>('');
  const [emailPlayback, setEmailPlayback] = useState<boolean>(false);
  const [emailCert, setEmailCert] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);



  const isValidEmailFormat = (email: string) => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setEmail(newValue);
    setIsValidEmail(newValue === '' || isValidEmailFormat(newValue));
  };



  useEffect(() => {
    if (profileState.profile) {
      setFullname(profileState.profile.fullname);
      setNickname(profileState.profile.login);
      if (profileState.profile.status)
        setBio(profileState.profile.status);
      setPhoneNumber(profileState.profile.phoneNumber);
      setGradeCode(profileState.profile.grade);
      setProvinceCode(profileState.profile.province);
      setSchool(profileState.profile.nameSchool);
      setEmailPlayback(profileState.profile.emailPlayback);
      setEmailCert(profileState.profile.emailCert);
      if (profileState.profile.emailSubscribe == 1) {
        setEmailSubscribe(true);
      }
      if (profileState.profile.email)
        setEmail(profileState.profile.email);
      if (profileState.profile.parentEmail)
        setParentEmail(profileState.profile.parentEmail);
    }
  }, [profileState.profile]);

  const saveProfile = (event: any) => {
    event.preventDefault();
    Promise.all([
      updateProfile(accessData,
        fullname,
        nickname,
        bio,
        phoneNumber,
        gradeCode,
        provinceCode,
        school,
        emailSubscribe,
        userEmail,
        emailPlayback,
        emailCert),
      uploader.current?.upload(),

    ]).then(() => {
      window.location.reload();
    });
  };

  return (
    <form onSubmit={saveProfile}>
      <div className="edit-profile-container">
        <div className="row">
          <div className="col-12 pt-3">
            <h1 className="edit-profile-title">แก้ไขข้อมูลส่วนตัว</h1>
          </div>
          <div className="col-12 pt-3 edit-profile-image-container">
            <UploadProfile ref={uploader} default={profileState.profile?.normalPhotoUrl} />
          </div>
          <div className="col-12 pt-3">
            <p className="m-0 font-weight-bold">ชื่อ-นามสกุล</p>
          </div>
          <div className="col-12 input-signup">
            <img src={userBlue} alt="" />
            <input placeholder="ชื่อ-นามสกุล"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              required
            >
            </input>
          </div>
          <div className="col-12 pt-3">
            <p className="m-0 font-weight-bold">ชื่อเล่น</p>
          </div>
          <div className="col-12 input-signup">
            <img src={userOrange} alt="" />
            <input placeholder="ชื่อเล่น"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              required
            >
            </input>
          </div>
          <div className="col-12 pt-3">
            <p className="m-0 font-weight-bold">แนะนำตัว</p>
          </div>
          <div className="col-12 input-signup">
            <img src={userGreen} alt="" />
            <input placeholder="แนะนำตัว"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            >
            </input>
          </div>
          <div className="col-12 pt-3">
            <p className="m-0 font-weight-bold">หมายเลขมือถือ</p>
          </div>
          <div className="col-12 input-signup">
            <img src={phone} alt="" />
            <input type="tel"
              placeholder="หมายเลขมือถือ"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              pattern="[0-9]{10}" minLength={10}
            >
            </input>
          </div>
          <div className="col-12  pt-3">
            <p className="m-0 font-weight-bold">ชั้นเรียน</p>
          </div>
          <div className="col-12 input-signup">
            <img src={grade} alt="" />
            <select value={gradeCode}
              onChange={(e) => {
                e.target.style.color = '#000000';
                setGradeCode(e.target.value);
              }}
              required
            >
              <option value="" hidden>ชั้นเรียน</option>
              {
                gradeState.grades.map((g, i) => (
                  <option key={i} value={g.grade}>
                    {g.descriptionTh}
                  </option>
                ))
              }
            </select>
            <img className="input-signup drop" src={dropDown} alt="" />
          </div>
          <div className="col-12  pt-3">
            <p className="m-0 font-weight-bold">จังหวัด</p>
          </div>
          <div className="col-12 input-signup">
            <img src={location} alt="" />
            <select value={provinceCode}
              onChange={(e) => {
                e.target.style.color = '#000000';
                setProvinceCode(parseInt(e.target.value));
              }}
              required
            >
              <option value="0" hidden>จังหวัด</option>
              {
                provinces.provinces.map((p, i) => (
                  <option key={i} value={p.province_code}>
                    {p.province_name_local}
                  </option>
                ))
              }
            </select>
            <img className="input-signup drop" src={dropDown} alt="" />
          </div>
          <div className="col-12 pt-3">
            <p className="m-0 font-weight-bold">โรงเรียน</p>
          </div>
          <div className="col-12 input-signup">
            <img src={grade} alt="" />
            <input placeholder="โรงเรียน"
              value={school}
              onChange={(e) => setSchool(e.target.value)}
            >
            </input>
          </div>

          <div className="col-12 pt-3">
            <p className="m-0 font-weight-bold">อีเมล {!isValidEmail && <span className="invalid-email">- รูปแบบอีเมลไม่ถูกต้อง</span>}</p>
          </div>
          <div className="col-12 input-signup">
            <img src={email} alt="" />
            <input
              placeholder="อีเมล"
              value={userEmail}
              onChange={(e) => handleEmailChange(e)}
            />
          </div>

          <div className="row email-notification-div">
            <div className="col-12 pt-3">การแจ้งเตือนอีเมล</div>
            <div className="col-2 pt-3"></div>
            <div className="col-10 pt-3" style={{ textAlign: "left" }}>
              <input className="premium-accept mr-2" type="checkbox" checked={emailSubscribe} onChange={(e) =>
                setEmailSubscribe(!emailSubscribe)} />
              <span onClick={() => setEmailSubscribe(!emailSubscribe)} style={{ cursor: "default" }}>ติดตามข่าวสารไลฟ์สอนประจำสัปดาห์ของเรา</span>
            </div>
            <div className="col-2 pt-3"></div>
            <div className="col-10 pt-3" style={{ textAlign: "left" }}>
              <input className="premium-accept mr-2" type="checkbox" checked={emailPlayback} onChange={(e) =>
                setEmailPlayback(!emailPlayback)} />
              <span onClick={() => setEmailPlayback(!emailPlayback)} style={{ cursor: "default" }}>ติดตามอัพเดทไลฟ์เมื่อเป็นไลฟ์ย้อนหลัง</span>
            </div>
            <div className="col-2 pt-3"></div>
            <div className="col-10 pt-3" style={{ textAlign: "left" }}>
              <input className="premium-accept mr-2" type="checkbox" checked={emailCert} onChange={(e) =>
                setEmailCert(!emailCert)} />
              <span onClick={() => setEmailCert(!emailCert)} style={{ cursor: "default" }}>รับใบรับรองเมื่อดูไลฟ์จบ</span>
            </div>
          </div>

          <div className="col-12 pt-4 save-edit-profile-container">
            {!isValidEmail && <p>รูปแบบอีเมลไม่ถูกต้อง</p>}
            <button
              className="save-edit-profile-button"
              type="submit"
              disabled={!isValidEmail}
            >
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditProfile;
