import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import PaymentStepOne from './PaymentRenders/PaymentStepOne';
import PaymentStepTwoSelection from './PaymentRenders/PaymentStepTwoSelection';
import PaymentStepTwoDetails from './PaymentRenders/PaymentStepTwoDetails';
import PaymentStepThree from './PaymentRenders/PaymentStepThree';
import PaymentStepPosition from './PaymentRenders/PaymentStepPosition';
import PaymentSubmitButton from './PaymentRenders/PaymentSubmitButton';
import PaymentCancelButton from './PaymentRenders/PaymentCancelButton';

import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Alert } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import { MixpanelTracking } from '../../utility';
import { generateToken } from '../../utility/Token';
import { cancelRequestPremium, reloadTransaction } from '../../stores/transaction/Transaction';


import './PaymentContent.css';
import 'react-day-picker/lib/style.css';

type MethodHandlers = { [key: number]: () => Promise<void> | void; };



const PaymentContent = (props: { packageId?: string, previousTransactionId?: number, from?: any } = { packageId: '0', }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [step, setStep] = useState(1);

    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);
    const profileState = useSelector((state: NaTypes.Store) => state.profile);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const at = accessData.accessToken ? accessData.accessToken : '';
    const aid = accessData.accountId ? accessData.accountId : '';
    const userToken = generateToken(aid, at);
    const currentTransaction = transaction.transactionList.find((t) => t.status === '3' || t.status === '4');
    const nowTransaction = transaction.transactionList[0];

    // Form Page 1
    const [name, setName] = useState('');
    const [surName, setSurName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

    // Form Page 2
    const [actualAmount, setActualAmount] = useState<number>(0.00);
    const [amount, setAmount] = useState<number>(1750.00);
    const [cardNumber, setCardNumber] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [cvv, setCvv] = useState('');
    const [dateTransfer, setDateTransfer] = useState(new Date());
    const [discountAmount, setDiscountAmount] = useState<number>(0.00);
    const [errorCode, setErrorCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isShowError, setIsShowError] = useState(false);
    const [method, setMethod] = useState(3);
    const [nameOnCard, setNameOnCard] = useState('');
    const [previousTransaction, setPreviousTransaction] = useState<any>(null);
    const [qrCodeBank, setQrCodeBank] = useState('');
    const [transactionInfo, setTransactionInfo] = useState<any>();



    if (currentTransaction && step === 1) {
        if (currentTransaction.status === '3') {
            let productActual = productList.find((t) => t.product_id === String(currentTransaction.product_id)) || productList[0];
            setStep(2); setEmail(currentTransaction.email); setAmount(parseFloat(currentTransaction.amount));
            setTransactionInfo(currentTransaction); setActualAmount(Number(productActual.price));
            if (currentTransaction.discount_id) setDiscountAmount(Number(productActual.price) - currentTransaction.amount);
            if (currentTransaction.product_is_promotion === '1') setMethod(1);
            if (currentTransaction.product_is_truemoney === '1') setPhone(currentTransaction.phone);
        } else if (currentTransaction.status === '4') {
            setStep(3); setEmail(currentTransaction.email); setTransactionInfo(currentTransaction);
        }
    }

    if (nowTransaction?.status === '1') history.push('/payment-history');



    const handlePaymentSubmission = async () => {
        switch (step) {
            case 1:
                await handleStepOne();
                break;
            case 2:
                await handleStepTwo();
                break;
            case 3:
                history.push('/payment-history');
                break;
            default:
                history.push('/');
                dispatch(cancelRequestPremium());
        }
    };

    const handleStepOne = async () => {
        const validPackageIds = productList.map(p => p.product_id);
        const defaultPackageId = validPackageIds[0];
        const packageId = (props.packageId && validPackageIds.includes(props.packageId)) ? props.packageId : defaultPackageId;

        const formData = new URLSearchParams({
            access_token: generateToken(aid, at), accountId: aid, fullName: `${name} ${surName}`.trim(),
            email, phone, from: props.from, packageId,
        });

        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v7/method/paymentSystem.banktranfer.one`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: formData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            if (!response.ok) throw new Error('Network response was not ok');

            dispatch(reloadTransaction(at, aid));

            const apiRes = await response.json();

            MixpanelTracking.premiumServiceRegistered(apiRes.transaction_id, name, surName, email, "", phone);

            if (apiRes.transaction_id && apiRes.product_is_email_notify === '1') {
                const notifyUrl = `${process.env.REACT_APP_API_URL_V2}/v1/email/notify`;
                const notifyResponse = await fetch(notifyUrl, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': userToken,
                    },
                    body: JSON.stringify({
                        product_id: apiRes.product_id,
                        transaction_id: apiRes.transaction_id,
                        step: 1,
                    }),
                });
                if (!notifyResponse.ok) throw new Error('Email notification response was not ok');
            }

        } catch (error) {
            console.error('Error handling payment submission:', error);
        }
    };

    const handleStepTwo = async () => {
        const methodHandlers: MethodHandlers = {
            1: () => handleBankTransfer('Prompt Pay'),
            2: () => handleBankTransfer('Bank Account'),
            3: () => handleCardPayment(),
            4: () => handleTrueMoneyPayment(),
            5: () => handleLinePayPayment(),
            6: () => handleShopeePay(),
        };
        const handler = methodHandlers[method];
        if (handler) await handler();
    };

    const handleBankTransfer = async (transferType: string) => {
        const formData = new URLSearchParams({
            access_token: generateToken(aid, at), accountId: aid, transactionId: currentTransaction.transaction_id,
            paymentMethod: method.toString(), prevExpireTime: previousTransaction?.expire_time || '',
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v7/method/paymentSystem.banktranfer.two`, {
                method: 'POST',
                body: formData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            if (!response.ok) throw new Error('Network response was not ok');

            const apiRes = await response.json();

            MixpanelTracking.premiumServicePurchased(
                currentTransaction.transaction_id, currentTransaction.product_description,
                parseInt(currentTransaction.amount), transferType, 'Paid'
            );
            ReactGA.event({ category: 'purchase', action: 'Purchased a Premium Service', value: amount, });

            if (apiRes.transaction_id) {
                await notifyPayment(apiRes); dispatch(reloadTransaction(at, aid)); setStep(3);
            }
        } catch (error) {
            console.error('Error handling bank transfer:', error);
        }
    };

    const notifyPayment = async (apiRes: any) => {
        await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/email/notify`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'token': userToken,
            },
            body: JSON.stringify({
                'product_id': apiRes.product_id,
                'transaction_id': apiRes.transaction_id,
                'step': 2,
            }),
        });
    };

    const handleCardPayment = () => {
        setIsLoading(true);
        window.Omise.setPublicKey(process.env.REACT_APP_OMISE_KEY);

        const tokenParams = {
            expiration_month: parseInt(expiryDate.split('/')[0]), expiration_year: parseInt('20'.concat((expiryDate.split('/')[1]))),
            name: nameOnCard, number: cardNumber.replace(/ /g, ''), security_code: cvv, amount: amount * 100, currency: 'THB'
        };

        window.Omise.createToken('card', tokenParams, (statusCode: any, response: any) => {
            if (statusCode === 200) {
                omiseCardHandler(response['id']);
            } else {
                setIsLoading(false); setIsShowError(true); setErrorMessage(response.message);
            }
        });
    };

    const omiseCardHandler = async (omiseToken: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/omise/subscribe`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': userToken,
                },
                body: JSON.stringify({
                    token: omiseToken,
                    transaction_id: currentTransaction.transaction_id,
                }),
            });
            if (!response.ok) throw new Error('Network response was not ok');

            const res = await response.json();
            setIsLoading(false);

            if (res.error === 0) {
                setIsShowError(false); setStep(3);

                MixpanelTracking.premiumServicePurchased(
                    currentTransaction.transaction_id, currentTransaction.product_description,
                    parseInt(currentTransaction.amount), 'Credit Card', 'Paid'
                );
                ReactGA.event({ category: 'purchase', action: 'Purchased a Premium Service', value: amount });

            } else if (res.error === 1) {
                setIsShowError(true); setErrorMessage(res.detail);
            }

        } catch (err) {
            setIsLoading(false);
            console.error('Error processing Omise card payment:', err);
        }
    };

    const handleTrueMoneyPayment = () => {
        setIsLoading(true);
        window.Omise.setPublicKey(process.env.REACT_APP_OMISE_KEY);

        const tokenParams = { amount: amount * 100, currency: 'THB', phone_number: phone, };

        window.Omise.createSource('truemoney', tokenParams, (statusCode: any, response: any) => {
            if (statusCode === 200) {
                omise3rdPartyHandler(response);
            } else {
                setIsLoading(false); setIsShowError(true); setErrorMessage(response.message);
            }
        });
    };

    const handleLinePayPayment = () => {
        setIsLoading(true);
        window.Omise.setPublicKey(process.env.REACT_APP_OMISE_KEY);

        const tokenParams = { amount: amount * 100, currency: 'THB', };

        window.Omise.createSource('rabbit_linepay', tokenParams, (statusCode: any, response: any) => {
            if (statusCode === 200) {
                omise3rdPartyHandler(response);
            } else {
                setIsLoading(false); setIsShowError(true); setErrorMessage(response.message);
            }
        });
    };

    const omise3rdPartyHandler = async (resOmiseSource: any) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/omise/charge`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': userToken,
                },
                body: JSON.stringify({
                    source: resOmiseSource,
                    transaction_id: currentTransaction.transaction_id,
                    return_uri: `${process.env.REACT_APP_WEB_URL}/payment-history`,
                }),
            });
            if (!response.ok) throw new Error('Network response was not ok');

            const res = await response.json();
            setIsLoading(false);

            if (res.error === 0) {
                setIsShowError(false);
                window.open(res.data.authorize_uri, '_self');

                MixpanelTracking.premiumServicePurchased(
                    currentTransaction.transaction_id, currentTransaction.product_description,
                    parseInt(currentTransaction.amount, 10), resOmiseSource.type, 'Paid'
                );
                ReactGA.event({ category: 'purchase', action: 'Purchased a Premium Service', value: amount, });

            } else if (res.error === 1) {
                setIsShowError(true); setErrorMessage(res.detail);
            }

        } catch (error) {
            setIsLoading(false);
            console.error('Error processing Omise 3rd party payment:', error);
        }
    };

    const handleShopeePay = async () => {
        try {
            const payUrl = `${process.env.REACT_APP_API_URL_V2}/v1/shopee/pay/${transactionInfo.transaction_id}`;
            const payResponse = await fetch(payUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'token': userToken,
                },
                body: JSON.stringify({
                    'platform_type': isMobile ? 'mweb' : 'pc',
                    'return_url': `${process.env.REACT_APP_WEB_URL}/payment-history`,
                    'from': props.from,
                }),
            });

            const apiRes = await payResponse.json();
            if (apiRes.errcode === 0) {
                const updateTransactionUrl = `${process.env.REACT_APP_API_URL_V2}/v1/payment/update/transaction`;
                const updateTransactionResponse = await fetch(updateTransactionUrl, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'token': userToken,
                    },
                    body: JSON.stringify({
                        'id': transactionInfo.transaction_id,
                        'status': 4,
                        'payment_method': 10,
                    }),
                });

                const resUpdateTransaction = await updateTransactionResponse.json();
                if (resUpdateTransaction.error === 0) {
                    window.open(apiRes.redirect_url_http, '_self');
                } else {
                    setIsShowError(true); setErrorMessage('Failed to update transaction status.');
                }
            } else {
                setIsShowError(true); setErrorMessage('Failed to pay through Shopee Pay.');
            }
        } catch (error) {
            console.error('Error during Shopee Pay handling:', error);
            setIsShowError(true); setErrorMessage('An unexpected error occurred.');
        }
    };



    useEffect(() => {
        if (props.previousTransactionId) {
            const t = transaction.transactionList.find((t) => t.transaction_id === props.previousTransactionId?.toString());
            setPreviousTransaction(t);
        }
    }, [props.previousTransactionId, transaction.transactionList]);

    useEffect(() => {
        if (profileState.profile) {
            const name = profileState.profile.fullname.split(" ");
            setName(name[0]);
            if (name.length > 1) setSurName(name[1]);
            setEmail(profileState.profile.parentEmail ? profileState.profile.parentEmail : profileState.profile.email);
            if (profileState.profile.phoneNumber) setPhone(profileState.profile.phoneNumber);
        }
    }, [profileState]);

    useEffect(() => {
        if (method === 1) {
            const interval = setInterval(() => { dispatch(reloadTransaction(at, aid)); }, 10000);
            return () => clearInterval(interval);
        }
    }, [method]);



    return <>
        <ToastContainer
            position="top-center" autoClose={3000} hideProgressBar newestOnTop closeOnClick
            rtl={false} pauseOnFocusLoss draggable pauseOnHover
        />

        <form onSubmit={(e) => { e.preventDefault(); handlePaymentSubmission(); }}>

            <PaymentStepPosition step={step} />

            {step === 1 && (
                <PaymentStepOne
                    name={name} surName={surName} email={email} phone={phone} isValidEmail={isValidEmail} setEmail={setEmail}
                    setIsValidEmail={setIsValidEmail} setName={setName} setSurName={setSurName} setPhone={setPhone}
                />
            )}

            {step === 2 && (
                <PaymentStepTwoSelection
                    actualAmount={actualAmount} amount={amount} couponCode={couponCode} discountAmount={discountAmount} errorCode={errorCode}
                    method={method} packageId={props.packageId} qrCodeBank={qrCodeBank} transactionInfo={transactionInfo} setActualAmount={setActualAmount}
                    setAmount={setAmount} setCouponCode={setCouponCode} setDiscountAmount={setDiscountAmount} setErrorCode={setErrorCode}
                    setErrorMessage={setErrorMessage} setMethod={setMethod} setQrCodeBank={setQrCodeBank} setTransactionInfo={setTransactionInfo}
                />
            )}

            {step === 2 && (
                <PaymentStepTwoDetails
                    amount={amount} cardNumber={cardNumber} cvv={cvv} dateTransfer={dateTransfer} expiryDate={expiryDate}
                    method={method} nameOnCard={nameOnCard} phone={phone} qrCodeBank={qrCodeBank} transactionInfo={transactionInfo}
                    setAmount={setAmount} setCardNumber={setCardNumber} setCvv={setCvv} setDateTransfer={setDateTransfer}
                    setExpiryDate={setExpiryDate} setNameOnCard={setNameOnCard} setPhone={setPhone}
                />
            )}

            {step === 3 && (<PaymentStepThree email={email} method={method} />)}

            {isShowError &&
                <div className="col-12 mt-4 p-0">
                    <Alert variant="danger" onClose={() => setIsShowError(false)} dismissible>
                        <Alert.Heading>Failed</Alert.Heading>
                        <p>{errorMessage}</p>
                    </Alert>
                </div>
            }

            <PaymentSubmitButton isLoading={isLoading} isValidEmail={isValidEmail} method={method} step={step} />

            {step === 2 && (<PaymentCancelButton />)}

        </form>
    </>;
};

export default PaymentContent;
