import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const PaymentSubmitButton = (props: {
    isLoading: boolean,
    isValidEmail: boolean,
    method: number,
    step: number,
}) => {

    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(true);
        if (props.step === 1) {
            setTimeout(() => {
                setClicked(false);
            }, 5000);
        }
    };

    return (
        <div className="col-12 mt-4 p-0 payment-submit-container">
            {!props.isValidEmail && <p className="invalid-email">รูปแบบอีเมลไม่ถูกต้อง</p>}
            {!props.isLoading ? (
                props.method === 1 ? (
                    <></>
                ) : (
                    <button
                        className={`w-100 premium-button ${props.step === 1 && clicked && props.isValidEmail ? 'disabled' : ''}`}
                        id="payment-submit-button"
                        type="submit"
                        disabled={!props.isValidEmail}
                        style={{ background: props.step === 3 ? '#4cd964' : '' }}
                        onClick={handleClick}
                    >
                        <p className="m-0">{props.step === 3 ? 'เสร็จสิ้น' : 'สมัครบริการ'}</p>
                    </button>
                )
            ) : (
                <div className="loading-button-spinner">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )}
        </div>
    );
};

export default PaymentSubmitButton;
