import React from 'react';
import { useHistory } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import './Mobile.css';
import Artwork1 from "../../images/mobile/Artwork1.png";
import Artwork2 from "../../images/mobile/Artwork2.jpg";
import Artwork3 from "../../images/mobile/Artwork3.png";
import Artwork4 from "../../images/mobile/Artwork4.png";
import Artwork6 from "../../images/mobile/Artwork6.jpg";
import Artwork7 from "../../images/mobile/Artwork7.jpg";
import Artwork8 from "../../images/mobile/Artwork8.jpg";
import Artwork9 from "../../images/mobile/Artwork9.png";

const artworksAndVideos = [
    Artwork1,
    'https://youtu.be/U3qV6JtctMg',
    Artwork2,
    Artwork3,
    'https://youtu.be/OBb4Hg1VjDM',
    Artwork4,
    Artwork6,
    Artwork7,
    Artwork8,
    Artwork9,
];

const Mobile = () => {

    const history = useHistory();
    if (isBrowser) history.push('/');

    return (
        <div className="mobile-container">
            {artworksAndVideos.map((item, index) => (
                item.includes('youtu.be') ? (
                    <div key={index} className="video-container">
                        <iframe
                            width="100%"
                            height="315"
                            src={`https://www.youtube.com/embed/${item.split('youtu.be/')[1]}`}
                            title={`YouTube Video Player ${index}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                ) : (
                    <img key={index} src={item} alt={`Artwork ${index + 1}`} className="artwork" />
                )
            ))}
        </div>
    );
};

export default Mobile;
