import packageOne from '../../images/prompt-pay/qr/2800.png';
import packageTwo from '../../images/prompt-pay/qr/4100.png';
import packageThree from '../../images/prompt-pay/qr/5300.png';
import packageFour from '../../images/prompt-pay/qr/6800.png';
import packageFive from '../../images/prompt-pay/qr/8300.png';
import packageSix from '../../images/prompt-pay/qr/9800.png';

const amountToImageMap: { [key: number]: string } = {
    2800: packageOne,
    4100: packageTwo,
    5300: packageThree,
    6800: packageFour,
    8300: packageFive,
    9800: packageSix,
};

export default amountToImageMap;